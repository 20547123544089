import { MetaTags } from "./meta-helper";

export const generateMetaTags = (path, page, replacements = []) => {
  const tags = JSON.parse(JSON.stringify(MetaTags()[page]));
  tags.meta = generateOpenGraphFromMeta(tags, replacements, path);
  tags.title = replacePlaceHolderIfNecessary(tags.title, replacements);

  return tags;
};

export const generateOpenGraphFromMeta = (
  predefinedTags,
  replacements,
  path,
) => {
  // filter out invalid og tags
  predefinedTags.meta.forEach(
    (t) => (t.content = replacePlaceHolderIfNecessary(t.content, replacements)),
  );

  const ogTagAccumulator = [
    {
      hid: "og:url",
      property: "og:url",
      content: `https:/esyoil.com${path}`,
    },
  ];

  const hasImageReplacement =
    replacements.filter((e) => e.k === "image").length > 0;

  if (!hasImageReplacement) {
    ogTagAccumulator.push({
      hid: "og:image",
      property: "og:image",
      content: `${process.env.PARTNER_MEDIA_CDN}/img/esyoil/esyoil-social-image.png`,
    });
  }

  const matchingOgTags = predefinedTags.meta.filter((e) => {
    const searchTags = ["title", "description"];
    hasImageReplacement && searchTags.push("image");

    return searchTags.includes(e.name);
  });

  matchingOgTags.forEach((tag) =>
    ogTagAccumulator.push({
      hid: `og:${tag.hid}`,
      property: `og:${tag.name}`,
      content: `${tag.content}`,
    }),
  );

  return [...ogTagAccumulator, ...predefinedTags.meta];
};

/**
 *
 * this function replaces ###key### value stuff in the meta strings.
 *
 * @param data key-value array of replacements ({ k: 'zipcode', v: '21339' })
 */
export const replacePlaceHolderIfNecessary = (input: string, data: any) => {
  data.forEach((r: any) => {
    const regex = new RegExp(`###${r.k}###`, "g");
    input = input.replace(regex, r.v);
  });

  return input;
};

export const tabExitHandler = (instance) => {
  let timer = null;
  window.onpagehide = async (e) => {
    if ({ focus: 1, pageshow: 1 }[e.type]) {
      // window.clearInterval(timer);
      window.clearTimeout(timer);
      document.title = instance.$root.$meta().refresh().metaInfo.titleChunk;
    } else {
      timer = setTimeout(() => {
        // TODO: possible memory leak
        document.title = "⭐ Bis gleich! 👍";
      }, 2500);
    }
  };
};
