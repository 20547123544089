// Libraries
import dayjs from "dayjs";
import "dayjs/locale/de";
import isoWeek from "dayjs/plugin/isoWeek";
import { BreadCrumbDetail } from "~/types/BreadCrumb";
dayjs.extend(isoWeek);
dayjs.locale("de");

// Functions
export const returnAuthorDataByID = function returnAuthorDataByID(
  authorID: number,
): {
  avatar_urls: {};
  description: string;
  id: number;
  link: string;
  name: string;
  simple_local_avatar: { full: string };
} {
  if (authorID === null) return;
  const Author = this.$store.state.blog.authors.find(
    (author) => author.id === authorID,
  );
  return Author;
};

export const returnPostDateSegment = function returnPostDateSegment(
  returnDateSegment: any,
  dateString: any,
) {
  dayjs.locale("de");
  let retVal = dayjs(dateString).format(returnDateSegment);
  if (returnDateSegment === "day") retVal = dayjs(dateString).format("DD");
  if (returnDateSegment === "weekDay")
    retVal = dayjs(dateString).format("dddd");
  if (returnDateSegment === "month") retVal = dayjs(dateString).format("MMMM");
  if (returnDateSegment === "year") retVal = dayjs(dateString).format("YYYY");

  return retVal;
};

export const getCalendarWeek = function (dateString: string): number {
  const dateObject = dayjs(dateString).startOf("day").day(4);

  const year: number = dateObject.year();

  const calendarWeek: number = Math.ceil(
    ((dateObject.valueOf() - dayjs().year(year).startOf("year").valueOf()) /
      86400000 +
      1) /
      7,
  );
  return calendarWeek;
};
export const generateNewsBreadcrumb = function (
  part: string,
  index: number,
  pathParts: any,
): Partial<BreadCrumbDetail> {
  const breadCrumb: Partial<BreadCrumbDetail> = {};

  if (index > 1) return null;
  const linkUrl = `/${pathParts.slice(0, index + 1).join("/")}`;

  breadCrumb.linkUrl = linkUrl;
  breadCrumb.linkValue = linkUrl;

  let name = "";
  switch (part.toLowerCase()) {
    case "news":
      name = "News";
      break;
    case "archiv":
      name = "News-Archiv";
      break;
    case "charts":
      name = "Charts";
      break;
    default:
      return null;
  }

  breadCrumb.name = name;
  return breadCrumb;
};

export const createNewsBreadCrumb = function (
  linkUrl: string,
  linkValue: string,
  name: string,
) {
  const breadCrumb: BreadCrumbDetail = {
    linkUrl: linkUrl,
    linkValue: linkValue,
    name: name,
  };
  return breadCrumb;
};

export default {
  returnAuthorDataByID,
  returnPostDateSegment,
  generateNewsBreadcrumb,
  createNewsBreadCrumb,
  getCalendarWeek,
};
